import React, { FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import NoContent from "components/no-content";
import InfiniteScroll from "react-infinite-scroller";
import MultaCard from "../multas.card";
import OrderReducer, { IOrderState } from '../order.reducer';
import FiltersReducer, { IFiltersState } from "../filters.reducer";
import { IMultas } from "gateways/tramites.interfaces";
import { TramitesGateway } from "gateways/tramites.gateway";
import IoC from "contexts/ioc.context";
import usePage from "hooks/page.hook";
import { LiteralsContext } from "containers/shared/literals";
import image from '../../../../resources/no-deuda.png';
import { translate } from "utils/i18n";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, WithStyles } from "@material-ui/core";
import { styles } from "../conductor";
import { useLocation } from "react-router";
import { mdiFileDownload, mdiFileExportOutline, mdiFileImportOutline } from "@mdi/js";
import Term from "components/term";
import { FILE_EXTENSION_XLSX, FILE_EXTENSION_CSV } from "constants/file-extension-types";
import Icon from "@mdi/react";
import { download } from "utils/download";
import useSujeto from "hooks/sujeto.hook";
import { AlertsContext } from "contexts/alerts.context";

import TableWizard from '../../../../components/table-wizard';
import WizardImporter from '../../../../components/wizard';
import TableWizardResult from 'components/table-wizard-result';
import { IMessageResponse, IResult } from "gateways/importer.interfaces";
import { ImporterGateway } from "gateways/importer.gateway";


interface IProps extends WithStyles<typeof styles> { 
    isRentACar: boolean;
    filters: IFiltersState,
    showExportImportDialog: boolean,
    handleMultasCount: (count: number) => void
    handleCloseExportImportDialog: () => void
    handleDownloadDatos: () => Promise<void>
    handlePrintJustificante: (list:any) => Promise<boolean>
    handleExportPlantillaNotificaciones: (fileExtension: 'csv' | 'xlsx')=> Promise<void>
}

const IdentificarConductor: FC<IProps> = ({
    classes,isRentACar, filters,showExportImportDialog, handleCloseExportImportDialog, handleMultasCount, 
    handleDownloadDatos, handlePrintJustificante, handleExportPlantillaNotificaciones
}) => {
    // TAB 0
    const numberElementsPerPage = 30;
    // Gateways 
    const ioc = useContext(IoC);
    const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);
    const importerG = useMemo(() => ioc.get(ImporterGateway) as ImporterGateway, [ioc]);

    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [page, setPage] = useState(0);
    const location = useLocation();

    const orderReducer = useReducer(OrderReducer, { orden: 1 });
    const [order, ] = orderReducer;
    const [multasCount, setMultasCount] = useState(0);
    const [loadingMultas, setLoadingMultas] = useState(true);
    const [loading, setLoading] = useState(false);

    const [multas, setMultas] = useState<IMultas[]>([]);
    const [idReciboAIdentificar, setIdReciboAIdentificar] = useState<number | undefined>(undefined);
    const [fileExtension, setFileExtension] = useState<'csv' | 'xlsx'>('xlsx');
    
    //Wizard
    const [loadingIdentificacionAccion, setLoadingIdentificacionAccion] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [dataToImport, setDataToImport] = useState<Record<string, any>[] | null>(null);
    const [resultData, setResultData] = useState<IResult[]>([]);
    const [errorsFile, setErrorsFile] = React.useState<IMessageResponse[]>([]);
    const [errorData, setErrorData] = React.useState<string>("");
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [numElementsImported, setNumElementsImported] = useState(1);
    const [importDisabled, setImportDisabled] = useState(false);
    const [listaMultasIdentificadas, setListaMultasIdentificadas] = useState<any[]>();
    // Memos
    const hasMore = useMemo(() => multasCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, multasCount]);
    const noContent = useMemo(() => !loadingMultas && multas.length === 0, [loadingMultas, multas]);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerPage),
            take: exportData ? (multasCount > 0 ? multasCount : numberElementsPerPage) : (numberElementsPerPage)
        });
    }, [multasCount, numberElementsPerPage]);

    const handleLoadMultas = useCallback(async (newFilters: IFiltersState, newOrder:IOrderState, newPage: number, activeTab: number = 0) => {
        try {
            setLoadingMultas(true);
            const query = buildQueryRequest(newFilters, newOrder, newPage);
            const multasReceived = await tramitesG.getMultasIdentificar(query);
            setMultas(newPage === 0 ? multasReceived : multas.concat(multasReceived));
            setLoadingMultas(false);
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        }
    }, [buildQueryRequest, tramitesG, multas,  pageDispatcher]); //buildQueryRequestPorRemesa, multasRemesa,

    const handleLoadMoreMultas = useCallback(() => {
        if (hasMore && !loadingMultas) {
            setPage(page + 1);
            handleLoadMultas(filters, order, page + 1, 0);
        }
    }, [hasMore, loadingMultas, page, handleLoadMultas, filters, order]);

    const handleIdentificar = useCallback((multa: IMultas) => {
        const multasIdentificadas = multas.filter(x => x.idIdentificacionWeb !== multa.idIdentificacionWeb);
        setMultas(multasIdentificadas);
        setMultasCount(multasIdentificadas.length);
        handleMultasCount(multasIdentificadas.length)
    }, [multas]);


    const handleNotAllowedDocuments = useCallback(() => {
        pageDispatcher({
            type: 'show-notification',
            payload: {
                message: translate('Tramites', 'soloPdfs', terms),
                variant: 'warning',
            }
        });
    }, [pageDispatcher, terms]);

    // Dialog
    const handleChangeFileExtension = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileExtension((event.target as HTMLInputElement).value as 'csv' | 'xlsx');
    };

    //Wizard
    const handlePrevious = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
    }, []);

    const handleNew = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }, []);

    const handleClose = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
        setOpen(false);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }, []);

    const handleUpload = React.useCallback(async (file: File) => {
        try {
            setLoadingUpload(true);
            setFile(file);
            const result = await importerG.importXLSX(file, 'conductor');
            if (result && result.data && result.data.length > 0) {
                setDataToImport(result.data);
                setResultData((result.data as any[])
                    .filter(item => !item.errors || item.errors.length === 0)
                    .map(item => ({
                        id: item.idIdentificacionWeb,
                        boletin: item.boletin,
                        refExpediente: item.refExpediente,
                        matricula: item.matricula,
                        nif: item.nif,
                        nombre: item.nombre,
                        apellidos: item.apellidos,
                        result: null,
                    })
                    ));
                setHasErrors((result.data as any[]).some(item => item.errors && item.errors.length > 0));
                setErrorData("");
            } else if (result && result.errors.length > 0) {
                setErrorsFile(result.errors);
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setLoadingUpload(false);
        }
    }, []);

    const handleIdentificarMultas = useCallback((multasIdentificadas: number[]) => {
        const matchingMultas = multas.filter(m => {
            return !multasIdentificadas.some(id => m.idIdentificacionWeb === id)
        });
        setMultas(matchingMultas);
        const count = multasCount - multasIdentificadas.length
        setMultasCount(count);
        handleMultasCount(count);
    }, [multas, multasCount]);

    const handleImport = React.useCallback(async () => {
        try {
            if (!dataToImport || !file) {
                return;
            }
            setImportDisabled(true);
            const idDocumento = await importerG.saveExcel(file);
            if (idDocumento) {
                const multasIdentificadas: number[] = [];
                const listMultasIdent: any[] = [];
                let newResultData = resultData;
                let newNumElementsImported = numElementsImported;
                for (const data of dataToImport) {
                    const resultToIdentificar = resultData.find(item => item.id === data.idIdentificacionWeb);
                    if (resultToIdentificar) {
                        const result = await importerG.identificarConductor(data, idDocumento);
                        newResultData = newResultData.map(item => {
                            if (item.id === resultToIdentificar.id) {
                                return { ...item, result };
                            }

                            return item;
                        });
                        newNumElementsImported++;
                        setNumElementsImported(newNumElementsImported);
                        setResultData(newResultData);
                        multasIdentificadas.push(parseInt(data.idIdentificacionWeb, 10));
                        listMultasIdent.push({
                            idRecibo: multas.filter((multa) => multa.idIdentificacionWeb === data.idIdentificacionWebinfoDispatchgetMultasIdentificarPorRemesa)[0]?.idRecibo,
                            idIdentificacionWeb: parseInt(data.idIdentificacionWeb, 10)
                        })
                    }
                }
                const result = await tramitesG.asociarJustificanteMasivoARecibos(listMultasIdent);
                setListaMultasIdentificadas(listMultasIdent);
                handleIdentificarMultas(multasIdentificadas);
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setImportDisabled(false);
        }
    }, [dataToImport, file, importerG, pageDispatcher, resultData]);

    const handlePrint = async() => {
        const done =  await handlePrintJustificante(listaMultasIdentificadas)
        setOpen(!done)
     }

    //Effects
    useEffect(() => {
        (async () => {
            console.log('effect filter- order ', filters)
            setMultas([]);
            setMultasCount(0);
            setPage(0);

            await handleLoadMultas(filters,order,0,0)

            const query: any = buildQueryRequest(filters, order, 0);
            delete query.skip;
            delete query.take;
            const countResult = await tramitesG.getCount(query);
            setMultasCount(countResult);
            handleMultasCount(countResult)
            
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, order]);


    useEffect(() => {
        const asyncEffect = async () => {
            await handleLoadMultas(filters,order,page,0)
        };
        asyncEffect();
    }, []);


    useEffect(() => {
        const idRecibo = location.state as number;
        if (idRecibo && multas.find(multa => multa.idRecibo === idRecibo)) {
            setIdReciboAIdentificar(idRecibo);
        }
    }, [location.state, multas])

    

    return (
        <>
            {loading ?
                <div className={classes.centerContent}>
                    <CircularProgress size={75} />
                </div>
            :
                <div>
                    <NoContent
                        image={image}
                        visible={noContent}
                        component='Tramites'
                        text={'No se han encontrado denuncias idenfiticables'}
                    />
                    {
                        multasCount > 0 ?
                            <>
                                <InfiniteScroll
                                    pageStart={0}
                                    initialLoad={false}
                                    loadMore={handleLoadMoreMultas}
                                    hasMore={hasMore}
                                    loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                >
                                    {multas.map((multa: IMultas, index: number) => (
                                        <MultaCard
                                            key={'Multa_' + index}
                                            handleIdentificar={handleIdentificar}
                                            multa={multa}
                                            isRentACar={isRentACar}
                                            handleNotAllowedDocuments={handleNotAllowedDocuments}
                                            idReciboAIdentificar={idReciboAIdentificar}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </>
                            : noContent
                                ? null
                                : <div key="progress" className={classes.progressContainer}>
                                    <CircularProgress />
                                </div>
                    }
                </div>
            }
            <Dialog
                open={showExportImportDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.identificacionDialog}
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    style={{ marginTop: 20 }}
                    className={classes.content}
                >
                    <span className={classes.title}>
                        <Term component="Tramites" text="TitleIdentificaciónMasiva" />
                    </span>
                </DialogTitle>
                <DialogContent className={classes.content}>
                <div>
                    <span><Term component="Tramites" text={'TextoIdentificacionMasiva'} /></span>
                </div>
                <div className={classes.actionsContainer}>
                    {
                        loadingIdentificacionAccion ?
                            <div className={classes.centerContent}>
                                <CircularProgress size={75} />
                            </div>
                            : 
                            <div className={classes.flexColumn}>
                                {/* Buttons export - import documents */}
                                <div className={classes.flexRow}>
                                    <div className={[classes.actionContent, classes.flexColumn].join(' ')}>
                                        <Button 
                                            onClick={ async() => {
                                                setLoadingIdentificacionAccion(true)
                                                await handleDownloadDatos()
                                                setLoadingIdentificacionAccion(false)
                                            }}
                                            variant='outlined'
                                        >
                                            <Icon path={mdiFileDownload}
                                                className={classes.actionIcon}
                                            />
                                            <span><Term component="Tramites" text={'DesacargarDatosAyuda'} /></span>
                                        </Button>
                                    </div>
                                    <div className={[classes.actionContent, classes.border, classes.flexColumn].join(' ')}>
                                        <Button 
                                            onClick={ async() => {
                                                setLoadingIdentificacionAccion(true)
                                                await handleExportPlantillaNotificaciones(fileExtension)
                                                setLoadingIdentificacionAccion(false)
                                            }}
                                        >
                                            <Icon path={mdiFileExportOutline}
                                                className={classes.actionIcon}
                                            />
                                            <span><Term component="Tramites" text={'ExportarPlantillaIdentificaciones'} /></span>
                                        </Button>

                                        {/* Radio buttons file type */}
                                        <FormControl className={[classes.formControlRadioBtns, classes.flexRow, classes.alignCenter, classes.justifyCenter].join(' ')}>
                                            <FormLabel id="demo-radio-buttons-group-label">
                                                <Term component="Tramites" text={'Format'} />
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                defaultValue="xlsx"
                                                value={fileExtension}
                                                onChange={handleChangeFileExtension}
                                                className={classes.flexRow}
                                            >
                                                <FormControlLabel value={FILE_EXTENSION_XLSX} control={<Radio color='primary' />} label=".xlsx" />
                                                <FormControlLabel value={FILE_EXTENSION_CSV} control={<Radio color='primary' />} label=".csv" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className={[classes.actionContent, classes.flexColumn].join(' ')}>
                                        <Button onClick={() => setOpen(true)} variant='outlined'>
                                            <Icon path={mdiFileImportOutline}
                                                className={classes.actionIcon}
                                            />
                                            <span><Term component="Tramites" text={'ImportarIdentificaciones'} /></span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        onClick={ async() => {
                            setLoadingIdentificacionAccion(true)
                            await handleCloseExportImportDialog()
                            setLoadingIdentificacionAccion(false)
                        }}
                        color="primary"
                        autoFocus
                        style={{ width: "100px" }}
                        variant='outlined'
                    >
                        <Term component={"Global"} text={"Cerrar"} />
                    </Button>
                </DialogActions>
            </Dialog>

            <WizardImporter
                open={open}
                title={translate('Wizard', 'identificacion_masiva', terms)}
                showIcon={true}
                dataElement={dataToImport ? <TableWizard data={dataToImport} hiddenCells={['idPais', 'idPoblacion']} /> : null}
                dataElementResult={resultData ? <TableWizardResult data={resultData} numElementsImported={numElementsImported} /> : null}
                disabledStep1={(errorsFile && errorsFile.length > 0) || !dataToImport}
                loadingUpload={loadingUpload}
                hasErrors={hasErrors}
                importDisabled={importDisabled}
                errorsFile={errorsFile}
                errorData={errorData}
                onUpload={handleUpload}
                onSubmit={handleImport}
                onPrevious={handlePrevious}
                onNew={handleNew}
                onClose={handleClose}
                onPrintJustificante={handlePrint} 
            />
        </>

    )
}

export default IdentificarConductor;