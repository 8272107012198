
// LIBRARY IMPORTS
import { useState, FC, useContext, useMemo, useCallback, useEffect } from 'react';
import { makeStyles, DialogTitle, DialogContent, useTheme, DialogActions, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, LinearProgress, DialogContentText, Typography } from '@material-ui/core';
// LOCAL IMPORTS
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { IDatosDomiciliacionGB, IErrorDomiciliacionGB } from 'gateways/datos.domiciliacion.interfaces';
import { IPais } from 'gateways/pais.interface';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { TextField } from 'components/text-field';
import { GenericModal } from 'components/generic-modal';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';

// STYLES IMPORTS
import styles from '../../../domiciliacion.styles';

const useStyles = makeStyles(styles);

interface IProps {
    open: boolean;
    iban: string;
    isPagoCarta: boolean;

    onClose: () => void;
    setDatosGB: (datos: IDatosDomiciliacionGB) => void
    onPagoCartaGBFin: (datos: IDatosDomiciliacionGB) => void;
    onContinuar: (datos: IDatosDomiciliacionGB) => void
}



const DialogDomiciliacionAltaGreatBritain: FC<IProps> = (props) => {
    const { open, iban, isPagoCarta, onClose, setDatosGB, onPagoCartaGBFin, onContinuar } = props;
    // HOOKS
    const terms = useContext(LiteralsContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const [, pageDispatcher] = usePage();
    // GATEWAYS 
    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);

    // LOADERS
    const [loading, setLoading] = useState(false);
    const [loadingPaises, setLoadingPaises] = useState(false);

    // VARIABLES
    const [errors, setErrors] = useState<IErrorDomiciliacionGB | null>();
    const [paisesOption, setPaisesOption] = useState<IPais[]>([]);

    // VARIABES OBLIGATORIAS DOMICILAICIÓN
    const [pais, setPais] = useState('');
    const [paisError, setPaisError] = useState(false);
    const [poblacion, setPoblacion] = useState('');
    const [poblacionError, setPoblacionError] = useState(false);
    const [direccion, setDireccion] = useState('');
    const [direccionError, setDireccionError] = useState(false);
    const [cp, setCP] = useState('');
    const [cpError, setCPError] = useState(false);

    // VARIABLES OPCIONALES DOMICILAICIÓN
    const [numero, setNumero] = useState('');
    const [km, setKm] = useState('');
    const [bloque, setBloque] = useState('');
    const [escalera, setEscalera] = useState('');
    const [portal, setPortal] = useState('');
    const [piso, setPiso] = useState('');
    const [puerta, setPuerta] = useState('');


    
    // HANDLERS

    const handlePais = (event: any) => {
        setPais(event.target.value.toUpperCase());
        setErrors({ pais: '' });
        setPaisError(false);
    }
    const handlePoblacion = (poblacion: string) => {
        setPoblacion(poblacion.toUpperCase());
        setErrors({ poblacion: '' });
        setPoblacionError(false);
    }
    const handleDireccion = (direccion: string) => {
        setDireccion(direccion.toUpperCase());
        setErrors({ direccion: '' });
        setDireccionError(false);
    }
    const handleCP = (cp: string) => {
        setCP(cp.toUpperCase());
        setErrors({ cp: '' });
        setCPError(false);
    }

    const handleNumero = (numero: string) => setNumero(numero.toUpperCase());
    const handleKm = (km: string) => setKm(km);
    const handleBloque = (bloque: string) => setBloque(bloque.toUpperCase());
    const handleEscalera = (escalera: string) => setEscalera(escalera.toUpperCase());
    const handlePortal = (portal: string) => setPortal(portal.toUpperCase());
    const handlePiso = (piso: string) => setPiso(piso.toUpperCase());
    const handlePuerta = (puerta: string) => setPuerta(puerta.toUpperCase());
   
    const handleDomiciliacionGB = async () => {
        if (!validateCamposGB()) return;
        
        const datosDireccionGB: IDatosDomiciliacionGB = {
            pais,
            poblacion,
            calle: direccion.trim(),
            cp,
            numero,
            km,
            bloque,
            escalera,
            portal,
            piso,
            puerta
        }

        if (isPagoCarta) {
            await setDatosGB(datosDireccionGB)
            onPagoCartaGBFin(datosDireccionGB)
            return;
        }
        onContinuar(datosDireccionGB) 
    }


    // VALIDATIONS
    const validateCamposGB = useCallback(() => {
        if (!pais) {
            setErrors({
                pais: translate('Domiciliaciones', 'El país del titular es obligatorio', terms),
            });
            return false;
        }
        if (!poblacion) {
            setErrors({
                poblacion: translate('Domiciliaciones', 'La población del titular es obligatoria', terms),
            });
            return false;
        }
        if (!direccion) {
            setErrors({
                direccion: translate('Domiciliaciones', 'La dirección del titular es obligatoria', terms),
            });
            return false;
        }
        if (!cp) {
            setErrors({
                cp: translate('Domiciliaciones', 'El código postal del titular es obligatorio', terms),
            });
            return false;
        }
        return true;
    }, [pais, poblacion, direccion, cp, terms]);


    // USE EFFECTS


    // USE EFFECTS

    useEffect(() => {
        setPaisError(errors && errors.pais ? errors.pais !== '' : false);
        setPoblacionError(errors && errors.poblacion ? errors.poblacion !== '' : false);
        setDireccionError(errors && errors.direccion ? errors.direccion !== '' : false);
        setCPError(errors && errors.cp ? errors.cp !== '' : false);
    }, [errors]);

    // TODO: generar hook de paises
    useEffect(() => {
        (async () => {
            setLoadingPaises(true);
            try {
                const isUkIBAN = iban.includes('GB');
                const paises: IPais[] = await perfilG.getPaises();
                const paisesOrdenAlfabetico = paises.sort((elementA, elementB) => {
                    return elementA.nombre !== elementB.nombre
                        ? elementA.nombre > elementB.nombre
                            ? 1
                            : -1
                        : 0;
                });

                if (!isUkIBAN) return;

                const uk = paises.find(p => p.nombre.toUpperCase() === 'REINO UNIDO');

                if (uk) setPais(uk.idPais);
                setPaisesOption(paisesOrdenAlfabetico);

            } catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingPaises(false);
            }
        })();
    }, [perfilG, iban, pageDispatcher]);



    return (
        <GenericModal open={open}>
            <DialogTitle>
                <Typography className={[classes.titleTextCard].join(' ')}>
                    <Term component="Domiciliaciones" text="Dirección tributaria" />
                </Typography>
            </DialogTitle>

            <DialogContentText className={classes.containerInfo}>
                <Term component="Domiciliaciones" text="textoInfoGB" />
            </DialogContentText>

            {/* TODO: generar selector de paises */}
            <DialogContent>
                <FormControl className={classes.formControl} fullWidth variant="outlined" margin="normal" error={paisError} style={{ width: "100%" }}>
                    {pais === '' &&
                        <InputLabel error={errors && errors.pais ? true : false}>
                            <Term component="Tramites" text="Pais" /> *
                        </InputLabel>
                    }
                    <Select
                        value={pais}
                        onChange={handlePais}
                        error={errors && errors.pais ? true : false}
                        disabled={true}
                    >
                        {paisesOption && paisesOption.map((pais: IPais) => {
                            return (
                                <MenuItem key={`pais${pais.idPais}`} value={pais.idPais}>{pais.nombre.toUpperCase()}</MenuItem>
                            );
                        })}
                    </Select>
                    {
                        loadingPaises ?
                            <LinearProgress className={classes.linearCharge} />
                            :
                            null
                    }
                    {errors && errors.pais ?
                        <Typography className={[classes.danger, classes.marginError].join(' ')} style={{ fontSize: '0.8rem' }}>{errors.pais}</Typography>
                        : null
                    }
                </FormControl>

                <div className={[classes.flexConatinerInputs,classes.mTop20].join(' ')}>
                    {/* Población */}
                    <TextField
                        id="poblacion"
                        value={poblacion}
                        label={translate('Tramites', 'poblacion', terms) + ' *'}

                        error={poblacionError}
                        helperText={errors && errors.poblacion ? errors.poblacion : ''}

                        onChange={(event) => handlePoblacion(event.target.value)}
                    />

                    {/* Dirección */}
                    <TextField
                        id="direccion"
                        value={direccion}
                        label={translate('Tramites', 'Dirección', terms) + ' *'}

                        error={direccionError}
                        helperText={errors && errors.direccion ? errors.direccion : ''}

                        onChange={(event) => handleDireccion(event.target.value)}
                    />
                </div>

                <div className={[classes.flexConatinerInputs,classes.mTop20].join(' ')}>
                    {/* Código Postal */}
                    <TextField
                        id="codigoPostal"
                        value={cp}
                        label={translate('Tramites', 'CodigoPostal', terms) + ' *'}

                        error={cpError}
                        helperText={errors && errors.cp ? errors.cp : ''}

                        onChange={(event) => handleCP(event.target.value)}
                    />

                    {/* Número */}
                    <TextField
                        id="numero"
                        value={numero}
                        label={translate('Tramites', 'Numero', terms)}
                        onChange={(event) => handleNumero(event.target.value)}
                    />

                    {/* Kilómetro */}
                    <TextField
                        id="km"
                        value={km}
                        label={translate('Domiciliaciones', 'km', terms)}
                        type={"number"}
                        onChange={(event) => handleKm(event.target.value)}
                    />

                    {/* Bloque */}
                    <TextField
                        id="bloque"
                        value={bloque}
                        label={translate('Tramites', 'Bloque', terms)}
                        onChange={(event) => handleBloque(event.target.value)}
                    />
                </div>

                <div className={[classes.flexConatinerInputs,classes.mTop20].join(' ')}>
                    {/* Escalera */}
                    <TextField
                        id="escalera"
                        value={escalera}
                        label={translate('Tramites', 'Escalera', terms)}
                        onChange={(event) => handleEscalera(event.target.value)}
                    />

                    {/* Portal */}
                    <TextField
                        id="portal"
                        value={portal}
                        label={translate('Domiciliacones', 'Portal', terms)}
                        onChange={(event) => handlePortal(event.target.value)}
                    />
                    {/* Piso */}
                    <TextField
                        id="piso"
                        value={piso}
                        label={translate('Tramites', 'Piso', terms)}
                        onChange={(event) => handlePiso(event.target.value)}
                    />

                    {/* Puerta */}
                    <TextField
                        id="puerta"
                        value={puerta}
                        label={translate('Domiciliaciones', 'Puerta', terms)}
                        onChange={(event) => handlePuerta(event.target.value)}
                    />
                </div>
            </DialogContent>


            <DialogActions className={classes.mTop40}>
                {/* Close */}
                <Button onClick={onClose} color="default" style={{ marginRight: 10}} >
                    <Term component="Global" text="Cerrar" />
                </Button>

                {/* Domiciliar */}
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonSuccess}
                    disabled={loading}
                    onClick={handleDomiciliacionGB}>
                    <Term component="Domiciliaciones" text= "Continuar" />
                </Button>
            </DialogActions>

            {
                loading &&
                <div className={classes.containerCircularProgress}>
                    <CircularProgress size={50} />
                </div>
            }
        </GenericModal>
    );
}

export { DialogDomiciliacionAltaGreatBritain };