import { mdiFormatListBulletedSquare , mdiFeatureSearchOutline, mdiFileCertificateOutline, mdiAccountSwitch, mdiFileUploadOutline, mdiAccountSearch,  mdiCircle, mdiEarth, mdiLoginVariant, mdiLogoutVariant, mdiPencil, mdiDotsVertical, mdiMenu, mdiFilter, mdiLaptop, mdiBank, mdiAccountSupervisorCircle, mdiBell, mdiWalletTravel, mdiFolderAccount, mdiMagnify, mdiViewList, mdiAccountMultiple, mdiAlphaCCircle, mdiAutoFix, mdiHandshakeOutline, mdiChevronLeft, mdiChevronRight, mdiAirplaneSettings, mdiCar, mdiWall, mdiDice3, mdiPrinterCheck, mdiCardAccountDetails, mdiCreditCardCheck, mdiCreditCardOff, mdiPrinterOffOutline, mdiPrinterEye, mdiRecycle, mdiMicrosoftExcel, mdiCheckboxMarkedCircleOutline, mdiCheckCircleOutline, mdiCloseCircle, mdiMagnifyPlusOutline, mdiFileDocumentEditOutline, mdiCellphone, mdiTabletCellphone, mdiInformationOutline, mdiTrashCan, mdiTrashCanOutline, mdiTeddyBear } from '@mdi/js';
import React, { FC, useMemo } from 'react';
import MdiIcon from '@mdi/react';

const availableIcons = {
    'dots-vertical': mdiDotsVertical,
    'login': mdiLoginVariant,
    'logout': mdiLogoutVariant,
    'pencil': mdiPencil,
    'menu': mdiMenu,
    'filter': mdiFilter,
    'laptop': mdiLaptop,
    'bank': mdiBank,
    'supervisor': mdiAccountSupervisorCircle,
    'bell': mdiBell,
    'wallet': mdiWalletTravel,
    'folder': mdiFolderAccount,
    'search': mdiMagnify,
    'view-list': mdiViewList,
    'people': mdiAccountMultiple,
    'c-circle': mdiAlphaCCircle,
    'wand': mdiAutoFix,
    'handshake': mdiHandshakeOutline,
    'chevron-right': mdiChevronRight,
    'chevron-left': mdiChevronLeft,
    'dice-3': mdiDice3,
    'earth': mdiEarth,
    'airplane-settings': mdiAirplaneSettings,
    'car': mdiCar,
    'wall': mdiWall,
    'list-bulleted': mdiFormatListBulletedSquare,
    'print-permitido': mdiPrinterCheck,
    'print-prohibido': mdiPrinterOffOutline,
    'print-certificado': mdiPrinterEye,
    'pay-permitido': mdiCreditCardCheck,
    'pay-certificado': mdiCardAccountDetails,
    'pay-prohibido': mdiCreditCardOff,
    'recycle': mdiRecycle,
    'excel': mdiMicrosoftExcel,
    'check-ok': mdiCheckboxMarkedCircleOutline,
    'check-ok-circle': mdiCheckCircleOutline,
    'check-error': mdiCloseCircle,
    'circle': mdiCircle,
    'account-search': mdiAccountSearch,
    'feature-search-outline': mdiFeatureSearchOutline,
    'file-upload-outline': mdiFileUploadOutline,
    'account-switch': mdiAccountSwitch,
    'file-certificate-outline': mdiFileCertificateOutline,
    'magnify-plus-outline': mdiMagnifyPlusOutline,
    'file-document-edit-outline': mdiFileDocumentEditOutline,
    'tablet-cellphone': mdiTabletCellphone,
    'cellphone': mdiCellphone,
    'info-outline': mdiInformationOutline,
    'trash': mdiTrashCan,
    'trash-outline': mdiTrashCanOutline,
    'teddy-bear': mdiTeddyBear
}

export type IconNames = keyof typeof availableIcons;

export type IconProps = {
    name: IconNames;
    title?: string;
    color?: string;
    size?: number;
};

export const Icon: FC<IconProps> = ({ name, title, color, size }) => {
    const iconPath = useMemo(() => 
        !availableIcons[name] ? availableIcons['account-search'] : availableIcons[name], 
    [name]);
    const iconSize = useMemo(() => typeof size !== 'undefined' ? size : 1, [size]);

    return (
        <MdiIcon path={iconPath} title={title} color={color} size={iconSize} />
    )
}