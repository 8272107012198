// LIBRARY IMPORTS
import React, { useContext, useEffect, useState, FC, useMemo, useCallback, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { WithStyles, withStyles, CircularProgress, Grid, Hidden, Card, CardContent, MenuItem } from '@material-ui/core';
import { mdiBank } from '@mdi/js';
// LOCAL IMPORTS
import { TributosGateway } from 'gateways/tributos.gateway';
import { RouteComponentProps, StaticContext } from 'react-router';
import { ITributo } from '../../gateways/tributos.interfaces';
import { IFindAllQuery, IFilterData, IRecibo } from 'gateways/recibo.interfaces';
import { DomiciliacionesSelectionContext } from 'contexts/domiciliaciones-selection.context';
import { LiteralsContext } from 'containers/shared/literals';
import { FiltersContext } from 'containers/cartero-virtual/filters.context';
import { FilterDataContext } from 'containers/cartero-virtual/filter.data.context';
import { AlertsContext } from 'contexts/alerts.context';
import { translate } from 'utils/i18n';
import { download } from 'utils/download';
import { ScreenType } from 'containers/cartero-virtual/filters.form';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { MoreInfoContext } from 'contexts/more_info.context';
import { CarritoDomiciliaciones } from './components/carrito-domiciliaciones';
import { TributoCard } from './components/cards/tributo-card/tributo.card';
import { SeleccionarDeseleccionarTodosButton } from './components/buttons/seleccionar-deseleccionar-todos';
import { NUMBER_ELEMENTS_PER_PAGE } from 'constants/domiciliaciones';
import { RecibosGateway } from 'gateways/recibo.gateway';
import FiltersReducer, { IFiltersState } from 'containers/cartero-virtual/filters.reducer';
import SelectionReducer, { ISelectionState } from 'containers/cartero-virtual/selection.reducer';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import Term from 'components/term';
import CustomTabs from 'components/custom-tabs';
import FiltersActive from '../cartero-virtual/filters.active';
import FiltersDialog from '../cartero-virtual/filters.dialog';
import PanelDrawer from '../cartero-virtual/panel.drawer';
import NoContent from 'components/no-content';
import image from '../../resources/no-deuda.png';
import useAltasDomicilaicion, { IStateHistory } from 'hooks/domiciliaciones/altas-domiciliacion.hook';
// STYLES IMPORTS
import styles from '../shared/tributos.styles';

interface IParams {
    tipo: 'domiciliables' | 'domiciliados';
    screen?: 'pago-carta' | 'domiciliados' | 'domiciliaciones';
}

type Props = WithStyles<typeof styles> & RouteComponentProps<IParams, StaticContext, IStateHistory>;

const Domiciliaciones: FC<Props> = ({ classes, match, history }) => {
    // VARIABLES
    const domiciliables = match.params.tipo === 'domiciliables';
    // HOOKS
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const { onAltaDomiciliaciones, onAltaPagoCarta, onModificacionPagoCarta, onModificacionFechaCargo } = useAltasDomicilaicion({ history, domiciliables });
    // REDUCERS
    const filtersReducer = useReducer(FiltersReducer, {});
    const [filters, filtersDispatch] = filtersReducer;
    // GATEWAYS
    const ioc = useContext(IoC);
    const tributosGateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    const recibosGateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);
    // BOOLEAN STATES
    const [loadingTributos, setLoadingTributos] = useState(false);
    const [loadingCountTributos, setLoadingCountTributos] = useState(false);
    const [selectingAll, setSelectingAll] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [isSelectedAllActivated, setSelectedAllActivated] = useState(false);
    // STATES
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);

    const [page, setPage] = useState(0);
    const [tributosCount, setTributosCount] = useState(0);

    const [tributos, setTributos] = useState<ITributo[]>([]);
    const [filterData, setFilterData] = useState<IFilterData>({});

    const [tabActive, setTabActive] = useState((!match.params.screen) || (match.params.screen && match.params.screen === 'pago-carta') ? 1 : 0);
    const [screen, setScreen] = useState<ScreenType | undefined>(match.params.screen);

    const [reciboDomiciliadoPagoCuenta, setReciboDomiciliadoPagoCuenta] = useState<IRecibo | undefined>(undefined);
    const [actionAproval, setActionAproval] = useState<boolean>(false);
    // MEMOS
    const hasMore = useMemo(() => tributosCount > (page === 0 ? (page + 1) : page) * NUMBER_ELEMENTS_PER_PAGE, [page, tributosCount]);
    const noContent = useMemo(() => !loadingTributos && !loadingCountTributos && tributosCount === 0, [loadingTributos, loadingCountTributos, tributosCount]);

    const handleCloseSubMenu = useCallback(() => setOpenSubMenuAnchorEl(null), []);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newPage: number): IFindAllQuery => {
        return Object.assign({}, newFilters, {
            domiciliados: (!domiciliables && screen === 'domiciliados') || undefined,
            domiciliables: domiciliables || undefined,
            domiciliadosPC: screen === 'pago-carta' || undefined,
            skip: newPage * NUMBER_ELEMENTS_PER_PAGE,
            take: NUMBER_ELEMENTS_PER_PAGE,
        });
    }, [domiciliables, screen])

    const handleExportRequest = useCallback(async () => {
        const query = buildQueryRequest(filters, page + 1);
        delete query.skip;
        query.take = 9999;
        const response = await tributosGateway.exportarDomiciliados(query);
        download(response.blob, alertsDispatch,
            translate('Global', 'No se ha podido exportar', terms),
            translate('Global', 'Exportar', terms), response.fileName);
    }, [alertsDispatch, buildQueryRequest, filters, page, terms, tributosGateway]);

    const handleSelectTributo = useCallback((selectionState: ISelectionState<ITributo>, tributosToSelected: ITributo | ITributo[]) => {
        const tributosArray = 'splice' in tributosToSelected ? tributosToSelected : [tributosToSelected];
        const tributosNoSeleccionados: ITributo[] = tributosArray.filter(x => selectionState.selected.findIndex(y => y.idRecibo === x.idRecibo) === -1);
        const tributosToAppend: ITributo[] = tributosNoSeleccionados.filter(x => x.domiciliacion?.domiciliable || x.pagoCarta?.DomiciliablePC);

        if ('splice' in tributosToSelected) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'info',
                    message: <Term component="Domiciliaciones" text={'Cuenta tributos seleccionados'} params={[selectionState.selected.length + tributosToAppend.length]} />
                }
            });
        } else {
            if (
                (screen === 'domiciliaciones' && !tributosToSelected.domiciliacion?.domiciliable) ||
                (screen === 'domiciliados' && !tributosToSelected.domiciliacion?.domiciliable) ||
                (screen === 'pago-carta' && !tributosToSelected.pagoCarta?.DomiciliablePC)
            ) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <div>
                            <p>
                                <Term component="Domiciliaciones" text='NoPermiteModificacion' />
                            </p>
                        </div>,
                        variant: 'warning',
                    }
                });
            }
        }

        return tributosToAppend;
    }, [alertsDispatch, screen]);
    const selectionRe = SelectionReducer<ITributo>({ isSelectable: handleSelectTributo });
    const selectionReducer = useReducer(selectionRe, { selected: [] });
    const [selection, selectionDispatch] = selectionReducer;
    const onlyDomiciliados = useMemo((() => !selection.selected[0]?.pagoCarta?.DomiciliadoPC && selection.selected[0] !== undefined), [selection.selected]);
    const onlyPagoCarta = useMemo((() => selection.selected[0]?.pagoCarta?.DomiciliadoPC || false), [selection.selected]);

    // Events
    const handleChangeTab = useCallback((_E: React.ChangeEvent<{}>, newValue: number) => {
        clearData();
        setScreen(newValue === 1 ? 'pago-carta' : 'domiciliados');
        setTabActive(newValue);
        history.push({
            pathname: `/cartero-virtual/domiciliaciones/domiciliados/${newValue === 1 ? 'pago-carta' : 'domiciliados'}`,
        })
    }, []);

    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
        handleCloseSubMenu();
    }, [handleCloseSubMenu]);

    const handleHideFilters = useCallback(() => setShowFilters(false), []);

    const handleLoadTributos = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        setLoadingTributos(true);
        const query = buildQueryRequest(newFilters, newPage);

        if (!query.domiciliables && !query.domiciliados && !query.domiciliadosPC) return;

        try {
            const tributosReceived = await tributosGateway.findAll(query);
            if (tributosReceived) {
                setTributos(newPage === 0 ? tributosReceived : tributos.concat(tributosReceived));
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoadingTributos(false);
        }
    }, [tributos, tributosGateway, pageDispatcher, buildQueryRequest]);

    const handleLoadMoreTributos = useCallback(() => {
        if (hasMore) {
            setPage(page + 1);
            handleLoadTributos(filters, page + 1);
        }
    }, [hasMore, page, handleLoadTributos, filters]);

    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(0);
        selectionDispatch({ type: 'clear' });
        handleLoadTributos(newFilters, 0);
    }, [handleLoadTributos, selectionDispatch]);

    const handleSelectAll = useCallback(async (domiciliadosFilt) => {
        let result: ITributo[] = [];
        let allTributos: ITributo[] = [];

        handleCloseSubMenu();
        setSelectingAll(true);
        setSelectedAllActivated(true);

        if (domiciliadosFilt) {
            allTributos = tributos.filter((x) => !x.pagoCarta?.DomiciliadoPC)
        }
        else {
            allTributos = tributos.filter((x) => x.pagoCarta?.DomiciliadoPC)
        }

        if (hasMore && filterData) {
            const query = buildQueryRequest(filters, 0);
            query.take = tributosCount - (query.skip || 0);
            query.domiciliados = domiciliadosFilt ? true : undefined;
            query.domiciliadosPC = !domiciliadosFilt ? true : false;

            result = await tributosGateway.findAll(query);

            if (result) {
                setTributos(result);
            }
        } else {
            result = allTributos;
        }

        selectionDispatch({ type: 'select', item: result });
        setSelectingAll(false);
    }, [handleCloseSubMenu, tributos, hasMore, filterData, selectionDispatch, buildQueryRequest, filters, tributosCount, tributosGateway]);

    const clearData = () => {
        setTributos([]);
        setPage(0);
        filtersDispatch({ type: 'clear' });
        selectionDispatch({ type: 'clear' });
        setSelectedAllActivated(false);
    };

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    const handleModificarPagoCuenta = async (tributo: ITributo) => {
        const recibos: IRecibo[] = await recibosGateway.findAll();
        const recibo = recibos.find(x => x.id === tributo.idRecibo);

        if (recibos.length < 1 || !recibo) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="Domiciliaciones" text="domiciliaciones_modif_pago_cuenta_recibo_not_found_error" />,
                    variant: 'error',
                }
            });
            return;
        }

        setReciboDomiciliadoPagoCuenta(recibo);
        alertsDispatch({
            type: 'show-alert',
            payload: {
                message: <Term component="Global" text="modificar_pago_cuenta_de_domiciliacion_description" />,
                variant: 'success',
                hasCustomAction: true,
                actionTemplateName: "modificar_pago_cuenta_de_domiciliacion",
                handleCustomAction: handleAprovAction,

            }
        });
    };

    const handleAprovAction = () => {
        setActionAproval(true);
    };

    useEffect(() => {
        if (!reciboDomiciliadoPagoCuenta || !actionAproval) return;

        alertsDispatch({ type: 'hide-alert' });
        // TODO: porque me hace pasar parámetros que no se usan? Únicamente necesitamos pasar el recibo
        history.push('/cartero-virtual/deuda-pendiente/pago-a-cuenta-domiciliado', { recibo: reciboDomiciliadoPagoCuenta, tributos, domiciliables, domiciliadoPc: false, isSelectedAllActivated, modifFechaVol: false, pagoCarta: false });
    }, [reciboDomiciliadoPagoCuenta, actionAproval]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBank,
                title: <Term component="Domiciliaciones" text={domiciliables ? 'Tributos domiciliables' : 'Tributos domiciliados'} />,
                moreInfoTemplate: domiciliables ? 'mas_info_tributos_domiciliables' : 'mas_info_tributos_domiciliados',
                right: (
                    <>
                        {
                            domiciliables
                                ?
                                <MenuItem button onClick={handleSelectAll}><Term component="Domiciliaciones" text="Domiciliar todos" /></MenuItem>
                                :
                                null
                        }
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                        <MenuItem button onClick={() => handleShowMasInfo(domiciliables ? 'mas_info_tributos_domiciliables' : 'mas_info_tributos_domiciliados')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms, domiciliables, handleToggleSubMenu, tributos]);

    useEffect(() => {
        const query = buildQueryRequest(filters, 0);
        delete query.skip;
        delete query.take;

        (async () => {
            const filterDataTemp = await tributosGateway.getFilterData(query);
            setFilterData(filterDataTemp ? filterDataTemp : {});
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domiciliables, screen]);

    useEffect(() => {
        (async () => {
            setLoadingCountTributos(true);
            setTributosCount(0);

            if (screen) {
                const query = buildQueryRequest(filters, 0);
                delete query.skip;
                delete query.take;

                handleFiltersChanged(filters);
                const tributosCountResult = await tributosGateway.getCount(query);

                setTributosCount(tributosCountResult ? tributosCountResult : 0);
                setLoadingCountTributos(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, screen])

    useEffect(() => {
        clearData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    useEffect(() => {
        clearData();
        setTabActive(!domiciliables && (!match.params.screen || match.params.screen === 'pago-carta') ? 1 : 0);
        setScreen(domiciliables
            ? 'domiciliaciones'
            : !match.params.screen || match.params.screen === 'pago-carta' ? 'pago-carta' : 'domiciliados'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domiciliables]);

    return (
        <FiltersContext.Provider value={filtersReducer}>
            <DomiciliacionesSelectionContext.Provider value={selectionReducer}>
                <FilterDataContext.Provider value={filterData}>
                    <div className={classes.root}>
                        <div className={classes.recibosContainer}>
                            <FiltersActive
                                onChange={handleFiltersChanged}
                            />
                            {domiciliables ?
                                null :
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                                    <CustomTabs
                                        labelTab1={translate('Domiciliaciones', 'tributos_domiciliados', terms)}
                                        labelTab2={translate('Domiciliaciones', 'pago_carta', terms)}
                                        onChange={handleChangeTab}
                                        tabActive={tabActive}
                                    />
                                </div>
                            }
                            <NoContent
                                image={image}
                                visible={noContent}
                                component='Domiciliaciones'
                                text={
                                    domiciliables
                                        ? 'No se ha encontrado deuda domiciliable'
                                        :
                                        screen === 'pago-carta'
                                            ?
                                            'No se han encontrado tributos en pago a la carta'
                                            :
                                            'No se han encontrado tributos domiciliados'
                                }
                            />
                            {
                                tributosCount > 0 ?
                                    <>
                                        <Card className={classes.cardContador}>
                                            <CardContent className={classes.cardContadorContainer} >
                                                <span className={classes.titleCardContador}>
                                                    <Term component="Domiciliaciones"
                                                        text={
                                                            domiciliables ? 'TituloCarteroVirtualDomiciliables' :
                                                                (screen === 'pago-carta' ? 'TituloCarteroVirtualPagoCarta' :
                                                                    'TituloCarteroVirtualDomiciliados')
                                                        }
                                                        params={[tributosCount]} />
                                                </span>

                                                <SeleccionarDeseleccionarTodosButton
                                                    screen={screen}
                                                    isSelectedAllActivated={isSelectedAllActivated}
                                                    selectingAll={selectingAll}
                                                    onlyDomiciliados={onlyDomiciliados}
                                                    onlyPagoCarta={onlyPagoCarta}
                                                    onSelectAll={handleSelectAll}
                                                    onClearData={clearData}
                                                />
                                            </CardContent>
                                        </Card>
                                        <InfiniteScroll
                                            pageStart={0}
                                            initialLoad={false}
                                            loadMore={handleLoadMoreTributos}
                                            hasMore={hasMore}
                                            loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.centerContent}><CircularProgress className={classes.progress} /></div>}
                                        >
                                            <Grid container>
                                                {tributos.map((tributo: ITributo, index: number) => (
                                                    <TributoCard
                                                        key={'Tributo_' + index}
                                                        tributo={tributo}
                                                        selectable={domiciliables === true}
                                                        visibleButton={true}
                                                        screen={screen}
                                                        isConfirmacionScreen={false}
                                                        multiplesRegistrosSeleccionados={selection.selected.length > 0}
                                                        domiciliables={domiciliables}
                                                        onlyDomiciliados={onlyDomiciliados}
                                                        onlyPagoCarta={onlyPagoCarta}
                                                        altaPagoCarta={(tributo: ITributo) => onAltaPagoCarta([tributo])}
                                                        handleFechaCargo={(tributo: ITributo) => onModificacionFechaCargo([tributo])}
                                                        onModificarPagoCuenta={handleModificarPagoCuenta}

                                                    />
                                                ))}
                                            </Grid>
                                        </InfiniteScroll>
                                    </>
                                    :
                                    noContent ?
                                        null
                                        :
                                        <div key="progress" className={classes.centerContent}><CircularProgress className={classes.progress} /></div>
                            }

                        </div>

                        <CarritoDomiciliaciones
                            items={selection.selected}
                            visible={selection.selected.length > 0}
                            literal={domiciliables ? "Continuar" : "Modificar cuenta"}
                            alta={false}
                            loading={false}
                            onSelect={() => onlyPagoCarta ? onModificacionPagoCarta(selection.selected, domiciliables) : onAltaDomiciliaciones(selection.selected, isSelectedAllActivated)}
                        />
                    </div>

                    <div className={classes.drawer}>
                        <Hidden mdUp implementation="css">
                            <FiltersDialog
                                open={showFilters}
                                screen="domiciliaciones"
                                onClose={handleHideFilters}
                                onChange={handleFiltersChanged}
                                onExportRequest={handleExportRequest}
                            />
                        </Hidden>

                        <Hidden smDown implementation="css">
                            <PanelDrawer
                                screen="domiciliaciones"
                                onFiltersChange={handleFiltersChanged}
                                onExportRequest={handleExportRequest}
                            />
                        </Hidden>
                    </div>
                </FilterDataContext.Provider>
            </DomiciliacionesSelectionContext.Provider>
        </FiltersContext.Provider >
    )
}

export default withStyles(styles)(Domiciliaciones);